import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import makeCarousel from "react-reveal/makeCarousel"
import Fade from "react-reveal/Fade"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import Ajmatova from "../assets/ajmatova.svg"

const CarouselUI = ({ children }) => <Container>{children}</Container>
const Carousel = makeCarousel(CarouselUI)

const ContactPage = ({ data }) => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "contact" })} />
      <div className="bg-gray-900">
        <div className="top-0 left-0 right-0 z-50 w-full max-w-2xl px-6 pt-3 mx-auto mt-24 bg-gray-100 md:absolute md:mt-40 md:py-12 md:px-12">
          <Fade delay={200}>
            <Ajmatova className="h-32 mx-auto my-5 " />
          </Fade>
          <span tw="pb-12 text-4xl font-bold text-gray-900 block  font-serif text-center w-full ">
            Centro de Estudios Ajmátova
          </span>
          <h1 tw="pb-2 text-4xl text-gray-900 font-sans mb-6 text-center w-full ">
            <FormattedMessage id="contact" />
          </h1>
          <div tw="flex flex-col items-start text-left">
            <div tw="max-w-6xl w-full font-sans text-lg mx-auto grid grid-cols-1">
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="flex flex-col mb-12 text-center text-gray-900 hover:opacity-60"
                href="mailto:centrodeestudiosajmatova@gmail.com"
              >
                centrodeestudiosajmatova@gmail.com
              </a>
              <div
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.instagram.com/centroestudiosajmatova/"
                className="flex flex-col mb-6 text-center text-gray-900"
              >
                @centroestudiosajmatova
                <div tw="text-lg inline-block mt-2 opacity-75">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    className="mx-1 hover:opacity-60"
                    href="https://www.instagram.com/centroestudiosajmatova/"
                  >
                    Instagram
                  </a>
                  &
                  <a
                    rel="noopener noreferrer"
                    className="mx-1 hover:opacity-60"
                    target="_blank"
                    href="https://www.facebook.com/Centro-de-estudios-Ajm%C3%A1tova-103420021349830/"
                  >
                    Facebook
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative opacity-20">
          <Carousel
            defaultWait={3000}
            maxTurns={100} /*wait for 1000 milliseconds*/
          >
            <Fade>
              <img
                alt="contacto"
                src="https://images.ctfassets.net/e99fpapc95u3/39EKpy8DXMBaLPZzt1MVmz/36f6d55bd306b3b1ab2328e4b68fc263/msedge_b8Zy0KMHIE.jpg"
                width="1699"
                height="867"
              />
            </Fade>
            <Fade>
              <img
                alt="contacto"
                src="https://images.ctfassets.net/e99fpapc95u3/7gfDOfzpvVjkeXIhupGYr0/658454253052aa7a93b228a34a729839/msedge_iZZxoQ5kvh.jpg"
                width="1918"
                height="1014"
              />
            </Fade>
            <Fade>
              <img
                alt="contacto"
                src="https://images.ctfassets.net/e99fpapc95u3/3mlxEyrErLTIk3Ndzpd9Gp/fbc95b89d5a0936d5433dcd07ae121fb/msedge_q3xTDgK9CY.jpg"
                width="1917"
                height="1015"
              />
            </Fade>
          </Carousel>
        </div>
      </div>
    </Layout>
  )
}
export default ContactPage

const Container = styled.div`
  ${tw`relative z-10 flex items-center justify-center w-full mx-auto overflow-hidden text-base bg-gray-800 md:block `}
  height: 100vh;

  .gatsby-image-wrapper {
    ${tw`h-screen `}
  }

  img {
    ${tw`object-cover w-full h-screen md:h-auto`}
  }
`
